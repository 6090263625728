<template>
  <div>
    <div class="flex-between">
      <div class="title">{{ $t("accountInformation") }}</div>
      <div class="flex-row">
        <!-- <i class="el-icon-message message" style="margin: 0 10px"></i>
        <span class="msg-num">0</span>-->
      </div>
    </div>
    <div class="flex-between info">
      <div class="flex-row">
        <img class="verify-img" src="../../static/img/company-img.png" />
        <div class="info-text">
          <span>{{ $t("brandOwnerNameColumn") }}{{ brandOwnerInfo.name }}</span>
          <span
              v-if="brandOwnerInfo.user"
            style="margin-left: 200px"
          >{{ $t("state") }}{{ brandOwnerInfo.user.approved === auth.UNKNOWN.value ? $t('responseStatus.unknown') : brandOwnerInfo.user.approved === auth.CONFIRM.value ? $t('responseStatus.confirm') : brandOwnerInfo.user.approved === auth.PROCESSING.value ? $t('responseStatus.processing') : brandOwnerInfo.user.approved === auth.REJECT.value ? $t('responseStatus.reject') : $t('responseStatus.unknown') }}</span>
          <div>{{ $t('socialCreditCode') }}{{ brandOwnerInfo.registeredNumber }}</div>
          <div>{{ $t('businessMail') }}{{ brandOwnerInfo.corporateEmail }}</div>
          <div>{{ 'Address : ' }}{{ brandOwnerInfo.user && brandOwnerInfo.user.address }}</div>
        </div>
      </div>
      <div style="height: 70px; line-height: 70px; float: right;">
        <el-button class="btn-black" @click="toDetails">{{ $t('see') }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import NP from 'number-precision';
import { connect, getFinanceRequests, getSupplierOverview } from '../../utils/blockchain';
export default {
  data() {
    return {
      symbol: this.$enums.INVESTORTYPE,
      auth: this.$enums.AUTH,
      brandOwnerInfo: {},
      outstandingAmount: 0,
      numberOutstandingTransactions: 0,
      totalHistoricalFinancingAmountCompleted: 0,
      historicalNumberFinancingTransactionCompleted: 0,
      address: ""
    };
  },
  mounted() {
    this.getBrandOwnerInfo();
  },
  methods: {
    getBrandOwnerInfo() {
      let _this = this;
      this.$axios.get("/v1/brand-owner/detail", {}).then(result => {
        if (!result.code) {
          _this.brandOwnerInfo = result.data;
        }
      }).catch(error => console.log(error));
    },
    msgClick() {
      this.$message({
        showClose: true,
        message: this.$t('noMessageDeal')
      });
    },
    toDetails() {
      this.$router.push({ path: "/enterprise-all/brand-owners-detail" });
    }
  },
};
</script>

<style lang="scss" scoped>
.message {
  font-size: 30px;
  color: #81d8d0;
}
.msg-num {
  color: #81d8d0;
  margin: 0 4px;
}
.info {
  //border-bottom: 1px solid #979797;
  padding-bottom: 10px;
  .info-text {
    margin-left: 20px;
    line-height: 30px;
  }
}
.pro-view {
  padding: 30px 20px;
  border-radius: 5px;
  border: 1px solid #979797;
  .order {
    margin-left: 10px;
    font-size: 18px;
    flex: 1;
    > div:nth-child(1) {
      font-weight: 500;
    }
    > div:nth-child(2) {
      margin: 10px 0;
    }
    > div:nth-child(3) {
      font-size: 14px;
    }
  }
  .deal {
    color: #81d8d0;
    margin-top: 20px;
    margin-left: 4px;
    &:hover {
      cursor: pointer;
      color: #ef6c00;
    }
  }
}
.top-view {
  height: 90px;
  border-bottom: 1px solid #979797;
  margin-bottom: 20px;
  line-height: 30px;
}
.title span.tip {
  font-size: small;
  font-weight: normal;
}
</style>
